<template>
  <div>
    <Block>
      <h1>Soins et techniques proposés</h1>
      <TMO class="care"/>
      <TherapeuticExercises class="care"/>
      <PhysiotherapyPrePostPartum class="care"/>
      <PelviPerineologyChildren class="care"/>
      <GymnastiqueHypopressive class="care"/>
      <FibrolyseDiacutanee class="care"/>
      <Taping class="care"/>
      <ScarAfterBurn class="care"/>
      <Npm class="care"/>
      <Electrolyse class="care"/>
    </Block>
  </div>
</template>

<script>
import Block from '@/components/Block'
import TMO from '@/components/cares/TMO'
import PhysiotherapyPrePostPartum from '@/components/cares/PhysiotherapyPrePostPartum'
import PelviPerineologyChildren from '@/components/cares/PelviPerineologyChildren'
import GymnastiqueHypopressive from '@/components/cares/GymnastiqueHypopressive'
import FibrolyseDiacutanee from '@/components/cares/FibrolyseDiacutanee'
import Taping from '@/components/cares/Taping'
import ScarAfterBurn from '@/components/cares/ScarAfterBurn'
import Npm from '@/components/cares/Npm'
import Electrolyse from '@/components/cares/Electrolyse'
import TherapeuticExercises from '@/components/cares/TherapeuticExercises'

export default {
  name: 'Soins',
  components: {
    TherapeuticExercises,
    ScarAfterBurn,
    Taping,
    FibrolyseDiacutanee,
    GymnastiqueHypopressive,
    PelviPerineologyChildren,
    PhysiotherapyPrePostPartum,
    TMO,
    Electrolyse,
    Npm,
    Block
  }
}
</script>

<style scoped>
.care + .care {
  margin-top: 42px;
}

</style>
