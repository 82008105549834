<template>
  <Card
    title="Fibrolyse Diacutanée"
    resume="Aussi appelée « crochetage », est une méthode de traitement dont le but est de diminuer les
douleurs, souvent liées à une perte de fonction ou à des adhérences entre les différents tissus."
  >
    <template v-slot:background>
      <div class="background" :style="{ backgroundImage: `url('${background}')` }"></div>
    </template>
    Cette technique, non invasive, fut créée par Kurt Ekmann, physiothérapeute suédois, qui en
    collaborant avec James Cyriax, décida de développer sa technique qui lui permit d’atteindre,
    avec ses crochets, plus spécifiquement et plus en profondeur les tissus, afin de rétablir les
    plans de mouvement entre les tissus. Les objectifs du crochetage se définissent par une action
    mécanique (adhérences entre les différents plans de glissement des tissus), une action
    circulatoire (lymphatique et sanguine) et une action réflexe (tonus musculaire).
  </Card>
</template>

<script>
import Card from '../Card/Card'

export default {
  name: 'FibrolyseDiacutanee',
  components: { Card },
  data: () => ({
    background: require('@/assets/cares/default.png')
  })
}
</script>

<style scoped>
.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
</style>
