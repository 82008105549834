<template>
  <Card
    title="Gymnastique Hypopressive"
    resume="Il s&#39;agit d&#39;exercices posturaux et respiratoires qui impliquent une activation de nombreux
muscles ainsi qu&#39;une diminution de la pression intra-abdominal, mais aussi au niveau du
plancher pelvien et la colonne vertébrale."
  >
    <template v-slot:background>
      <div class="background" :style="{ backgroundImage: `url('${background}')` }"></div>
    </template>
    <p>
      Il comporte des exercices importants et efficaces pour prévenir différents types de
      symptômes/pathologies et permettent une amélioration quant au rendement physique et esthétique.</p>
    Les bénéfices de cette méthode sont:
    <ul>
      <li>Activation de la musculature abdomino-périnéale</li>
      <li>Réeducation posturale</li>
      <li>Diminution du périmètre de la ceinture pelvienne</li>
      <li>Prévention des différentes dysfonctions trop souvent rencontrées telles que les troubles de la sphère
        intime (incontinences, constipation, etc...) autant chez la femme après accouchement, mais de plus en plus
        chez la plupart des sportifs.
      </li>
    </ul>
    <a target="_blank" href="https://www.hipopresivos.com/">www.hipopresivos.com</a>
  </Card>
</template>

<script>
import Card from '../Card/Card'

export default {
  name: 'GymnastiqueHypopressive',
  components: { Card },
  data: () => ({
    background: require('@/assets/cares/default.png')
  })
}
</script>

<style scoped>
.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
</style>
