<template>
  <Card
    title="Physiothérapie de la cicatrice après une brûlure grave"
    resume="Les objectifs du bilan et du traitement de la cicatrice après une brûlure grave permettent de
lutter contre l&#39;hypertrophie, les adhérences, les rétractions et les attractions au niveau du
derme."
  >
    <template v-slot:background>
      <div class="background" :style="{ backgroundImage: `url('${background}')` }"></div>
    </template>
    <p>Les objectifs du bilan et du traitement de la cicatrice après une brûlure grave permettent de lutter contre
      l'hypertrophie, les adhérences, les rétractions et les attractions au niveau du derme. Il est essentiel de
      connaître et transmettre aux patients les données fondamentales concernant la cicatrice, l’évaluation de
      celle-ci, le traitement, les conseils, la prévention, l'hydratation ainsi que la guidance du remaniement de la
      peau.</p>
    <p>Lien : <a target="_blank" href="https://www.flavie.ch/">www.flavie.ch</a></p>
  </Card>
</template>

<script>
import Card from '../Card/Card'

export default {
  name: 'ScarAfterBurn',
  components: { Card },
  data: () => ({
    background: require('@/assets/cares/default.png')
  })

}
</script>

<style scoped>
.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
</style>
