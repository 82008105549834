<template>
  <Card title="Exercices thérapeutiques"
        resume="Les exercices thérapeutiques sont un des piliers fondamentaux de la plupart des traitements en physiothérapie."
  >
    <template v-slot:background>
      <div class="background" :style="{ backgroundImage: `url('${background}')` }"></div>
    </template>
    La plupart des problèmes musculo-squelettiques ont comme point commun la dysfonction soit articulaire, musculaire ou
    nerveuse. Par conséquent, la dysfonction peut s'accompagner de la douleur. Le bilan précis des différentes
    dysfonctions permettra de poser un diagnostic physiothérapeutique et de déterminer avec précision les bons exercices
    ainsi que le dosage adéquat, selon la condition du patient.
  </Card>
</template>

<script>
import Card from '../Card/Card'

export default {
  name: 'TherapeuticExercises',
  components: { Card },
  data: () => ({
    background: require('@/assets/cares/default.png')
  })
}
</script>

<style scoped>
.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
</style>
